<template>
  <div class="all_main_wrap" style="min-height: 100%">
    <HeaderBox>
      <template #title> 成绩设置 </template>
      <template #input>
        <a-button
          class="all_boder_btn"
          @click="
            state = 1;
            visible = true;
          "
          >新建</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.configId"
        @change="onPage"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a
              @click="
                state = 2;
                (visible = true), (name = row.name), (configId = row.configId),(isNotDel = row.isNotDel);
              "
              >编辑</a
            >
            <span v-show="row.isNotDel">|</span>
            <a v-show="row.isNotDel" @click="delMangeItem(row.configId)">删除</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 新建/编辑 -->
    <!-- <a-modal v-model="visible" :title="(state==1?'新建':'编辑')+'成绩字段'">
      <p style="padding-bottom:10px;padding-left:16px;">
        <a @click="list.push({})">添加自定义字段</a>
      </p>
      <a-row class="rows" v-for="(item,index) in list" :key="index">
        <a-col span="6" class="left">自定义字段{{index+1}}</a-col>
        <a-col span="18" class="right flex">
          <a-input placeholder="请输入字段名" />
          <a @click="list.push({})">删除</a>
        </a-col>
      </a-row>
    </a-modal> -->
    <a-modal
      v-model="visible"
      :title="(state == 1 ? '新建' : '编辑') + '成绩名称'"
    >
      <template slot="footer">
        <a-button key="back" @click="cancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="change"
        >
          确定
        </a-button>
      </template>
      <div class="modal_box">
        <p style="font-size: 16px; flex-shrink: 0">成绩名称：</p>
        <a-input placeholder="请输入成绩名称" v-model="name" />
      </div>
      <div class="modal_box">
        <p style="font-size: 16px; flex-shrink: 0;margin-right:15px">是否允许删除:</p>
        <a-radio-group v-model="isNotDel" name="radioGroup">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "成绩名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      state: 1, // 1新建，2编辑
      visible: false,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      list: [],
      name: "", // 新建-字段名
      isNotDel: 1,
      configId: "", // 编辑id
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    // tab切换
    callback(e) {},
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: { id: 1 },
      });
    },
    cancel() {
      this.visible = false;
      this.name = "";
    },
    // 获取成绩设置列表
    getManageList() {
      this.$ajax({
        url: "/hxclass-management/exam/mark/config/list",
        method: "get",
        params: {
          current: this.pageNumber, // 页码
          size: this.pageSize, // 条数
          name: "",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 新增、编辑成绩字段
    change() {
      if (!this.name) {
        this.$message.warning("请填写成绩名称");
        return;
      }
      this.loading = true;
      this.$ajax({
        url:
          this.state == 1
            ? "/hxclass-management/exam/mark/config/save"
            : "/hxclass-management/exam/mark/config/edit",
        method: this.state == 1 ? "post" : "put",
        params: {
          configId: this.state == 1 ? "" : this.configId,
          name: this.name,
          deleted: 0,
          isNotDel: this.isNotDel,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.visible = false;
          this.name = "";

          this.$message.success("添加成功");
          this.getManageList();
        } else {
          this.$message.error(res.message);
          this.visible = false;

          this.name = "";
        }
        this.loading = false;
      });
    },
    // 删除对象
    delMangeItem(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url: "/hxclass-management/exam/mark/config/del?configId=" + e,
              method: "delete",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.pageNumber = 1;
                _this.getManageList();
              } else {
                _this.$message.error(res.message);
              }
            });
        },
        onCancel() {},
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList(); // 获取成绩设置列表
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  /deep/.ant-table {
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
.rows {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    input {
      flex: 1;
    }
    a {
      margin-left: 8px;
    }
  }
}
</style>
