var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap",staticStyle:{"min-height":"100%"}},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 成绩设置 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-button',{staticClass:"all_boder_btn",on:{"click":function($event){_vm.state = 1;
          _vm.visible = true;}}},[_vm._v("新建")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.configId; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        hideOnSinglePage: true,
        total: _vm.total,
        current: _vm.pageNumber,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNumber - 1) * _vm.pageSize + i + 1)+" ")])]}},{key:"operation",fn:function(item, row){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){_vm.state = 2;
              (_vm.visible = true), (_vm.name = row.name), (_vm.configId = row.configId),(_vm.isNotDel = row.isNotDel);}}},[_vm._v("编辑")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(row.isNotDel),expression:"row.isNotDel"}]},[_vm._v("|")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.isNotDel),expression:"row.isNotDel"}],on:{"click":function($event){return _vm.delMangeItem(row.configId)}}},[_vm._v("删除")])])]}}])})],1),_c('a-modal',{attrs:{"title":(_vm.state == 1 ? '新建' : '编辑') + '成绩名称'},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.change}},[_vm._v(" 确定 ")])],1),_c('div',{staticClass:"modal_box"},[_c('p',{staticStyle:{"font-size":"16px","flex-shrink":"0"}},[_vm._v("成绩名称：")]),_c('a-input',{attrs:{"placeholder":"请输入成绩名称"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"modal_box"},[_c('p',{staticStyle:{"font-size":"16px","flex-shrink":"0","margin-right":"15px"}},[_vm._v("是否允许删除:")]),_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.isNotDel),callback:function ($$v) {_vm.isNotDel=$$v},expression:"isNotDel"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":0}},[_vm._v("否")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }